import { useContext, useEffect, useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import './index.css';
import { app,appCheck } from "../../Firebase/config";
import { AuthContext } from "../../Firebase/context";
import axios from 'axios';
import Timer from '../../components/Timer';
const { getToken } = require('firebase/app-check');

export default function SignUpInfo() {

    //TODO: /info should be protected
    const { user,domain,manual} = useContext(AuthContext);
    const history = useHistory();
    let email = '';

    const reSend = async () => {
        const apiUrl = process.env.REACT_APP_pluginService;

        let appCheckTokenResponse;

        try {
            appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
        } catch (err) {
            console.log(err);
        }

        const data = {
            customer: email,
            domain: domain
        }

        await axios.post(apiUrl, data,{
            headers: {
                'X-Firebase-AppCheck': appCheckTokenResponse.token,
            }
        })
            .then(res => {
                console.log('resend email');
                TriggerTimer();
            }).catch((err) => {
                console.log(err);
            })
    }

    const LogOut = () => {
        app.auth().signOut().then(res => {
            if (res) {
                history.push("/login")
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const [timeLeft, setTimeLeft] = useState(null);
    const [targetTime, setTargetTime] = useState(null);
    const [activeResend, setActiveResend] = useState(false);

    let resendTimeInterval;

    const calculateTimeLeft = (finalTime) => {
        const difference = finalTime - +new Date();//+new Date() convert date to integer
        if (difference >= 0) {
            setTimeLeft(Math.round(difference / 1000));//difference is millisecond
        } else {
            setTimeLeft(null);
            clearInterval(resendTimeInterval);
            setActiveResend(true);
        }
    }

    const TriggerTimer = (targetTimeInSeconds = 60) => {
        setTargetTime(targetTimeInSeconds);
        setActiveResend(false);
        const finalTime = +new Date() + targetTimeInSeconds * 1000;
        resendTimeInterval = setInterval(() => (//refresh every second
            calculateTimeLeft(finalTime), 1000
        ))
    }

    useEffect(() => {
        TriggerTimer();

        return () => {
            clearInterval(resendTimeInterval);//prevent memory leak
        }
    }, []);

    if (user) {
        email = user.multiFactor.user.email;
    }

    return (
        <div className="login-container">
            <div className="login-content">
                {user ? (
                    <div>
                        <h1 className="login-title">Congratulations!</h1>
                        {/* <div className="info-content">{(!created && existed) ? (<article>You've signed up for DTonomy. Please check the inbox of {email} for login information.
                        </article>) : <article>Congratulations! You've successfully signed up for DTonomy. Please check your inbox {email} for login information.
                            If you need any help,please contact help@dtonomy.com.
                        </article>}</div> */}
                        <div className="info-content">
                            {
                                manual?(<article>
                                    You've signed up for DTonomy. Our team will send login information to your inbox shortly.
                                </article>):(<article>
                                You've signed up for DTonomy. Please check the inbox of {email} for login information.
                            </article>)
                            }
                        </div>
                        <button className='newAcct' onClick={LogOut}>Sign up with another account</button>
                        {manual?null:<Timer
                            activeResend={activeResend}
                            timeLeft={timeLeft}
                            targetTime={targetTime}
                            reSend={reSend}
                        />}
                    </div>
                ) : (
                    <Redirect to={{ pathname: "/login" }} />
                )}
            </div>
        </div>
    );
}
