import React from 'react';
import './index.css';

export default function Timer({ activeResend, timeLeft, targetTime, reSend }) {
    return (
        <div>
            <div className='warning'>
                <span>Didn't receive the email?</span>
                <button className='reSend' disabled={!activeResend} style={{ opacity: !activeResend && 0.5 }} onClick={reSend}>Resend</button>
            </div>
            {!activeResend && (
                <div className='timer'>
                    in <span className='count'>{timeLeft || targetTime}</span> second(s)
                </div>
            )}
        </div>
    )
}