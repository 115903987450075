import { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './index.css'

class Introduction extends Component {
    render() {
        return (
            <div>
                <div className="header">
                    <div className="header-logo">
                        <a href='https://www.dtonomy.com/'><img src='https://s1.ax1x.com/2022/03/19/qkxAsO.png' width='160' height='45' /></a>
                    </div>
                </div>
                <div className="content">
                    <div className="content-1">
                        Accelerate security alerts Analysis and Response by 80%
                    </div>
                    <div className="content-2">
                        <img src="https://s1.ax1x.com/2022/04/08/LpGXs1.png" width='22' height='22' /><span className="content-2-span">No-code security automation</span>
                    </div>
                    {/* <div className="content-2">
                        <img src="https://s1.ax1x.com/2022/04/08/LpGXs1.png" width='22' height='22' /><span className="content-2-span">AI-based pattern discovery and alerts correlation</span>
                    </div> */}
                    <div className="content-2">
                        <img src="https://s1.ax1x.com/2022/04/08/LpGXs1.png" width='22' height='22' /><span className="content-2-span">Supercharge your existing security investments</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default Introduction;