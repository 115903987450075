import './App.css';
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import SignUpInfo from './pages/SignUpInfo';
import Login from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Introduction from './components/Introduction';
import { AuthProvider } from './Firebase/context';

class App extends Component {

  render() {

    return (
      <div className="outer-container">
        <div className="left-container">
          <Introduction />
        </div>
        <AuthProvider>
          <div className="right-container">
            <Switch>
              <Route path='/login' component={Login} />
              <Route path='/info' component={SignUpInfo} />
              <Redirect to="/login" />
            </Switch>
          </div>
        </AuthProvider>
      </div>
    );
  }
}

export default App;
