import React, { Component, useContext, useEffect } from 'react';
import './index.css';
import { Redirect } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { FirebaseAuth } from "react-firebaseui";
import { AuthContext } from "../../Firebase/context"
import axios from 'axios';
import { appCheck } from "../../Firebase/config";
const { getToken } = require('firebase/app-check');

export default function Login() {

    const { user, setExisted, setCreated,setDomain,setManual } = useContext(AuthContext);

    // should define as let and re-assign the value to them after user login.
    let provider = '';
    let email = '';;
    let firstName = '';
    let lastName = '';

    const uiConfig = {
        signInFlow: "popup",
        signInSuccessUrl: '/info',
        signInOptions: [
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                buttonColor: '#2F2F2F',
                fullLabel: 'Sign up with Google'
            },
            {
                provider: 'microsoft.com',
                fullLabel: 'Sign up with Microsoft'
            }
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
        },
    };

    const findAvailableVM = async (token,index) => {

        const apiUrl = process.env.REACT_APP_pluginService;

        const fetchVMUrl = apiUrl + `${index}/${email}/${provider}`

        await axios.get(fetchVMUrl, {
            headers: {
                'X-Firebase-AppCheck': token,
            }
        })
            .then(res => {
                if (res.data.domain) {
                    addUser(res,token);
                }else{
                    setManual(true);
                    addUserManually(token);
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    const addUserManually=async(token)=>{
        const apiUrl = process.env.REACT_APP_pluginService+`add`;

        const data = {
            customer: email,
            provider:provider
        }

        await axios.post(apiUrl, data, {
            headers: {
                'X-Firebase-AppCheck': token,
            }
        })
            .then(res => {
                console.log('add user manually');
            }).catch((err) => {
                console.log(err);
            })
    }

    const addUser = async (VMInfo, token) => {
        setDomain(VMInfo.data.domain);

        const addUserUrl = VMInfo.data.server + `/api/agent/v1/user?tenantId=${VMInfo.data.tenantId}&accesskey=${VMInfo.data.accesskey}`

        const userInfo = {
            email: email,
            isActive: true,
            firstName: firstName,
            lastName: lastName,
            role: "Admin",
            type: provider.charAt(0).toUpperCase()+provider.slice(1)
        }

        // TODO: Authorization will lost in axios.post call
        // const headers = {
        //     "Authorization": `${VMInfo.data.accesskey}`
        //   }

        await axios.post(addUserUrl, userInfo)
            .then(res => {
                if (res.data) {
                    sendEmail(token,VMInfo.data.domain);
                    sendEmailNewUser(token);
                }
            }).catch((err) => {
                console.log(err);
            })

    }

    const sendEmail = async (token,domain) => {
        const apiUrl = process.env.REACT_APP_pluginService;

        const data = {
            customer: email,
            // must pass domain as parameter here,don't use context. child components will only receive updated-context after
            // re-render, but this code chain is executed before re-render.
            domain: domain 
        }

        await axios.post(apiUrl, data, {
            headers: {
                'X-Firebase-AppCheck': token,
            }
        })
            .then(res => {
                console.log('send login info');
            }).catch((err) => {
                console.log(err);
            })
    }

    const sendEmailNewUser = async (token) => {
        const apiUrl = process.env.REACT_APP_pluginService+`new`;

        const data = {
            customer: email,
        }

        await axios.post(apiUrl, data, {
            headers: {
                'X-Firebase-AppCheck': token,
            }
        })
            .then(res => {
                console.log('created new user');
            }).catch((err) => {
                console.log(err);
            })
    }


    const isExisted = async (index) => {

        const apiUrl = process.env.REACT_APP_pluginService;

        const fetchUserUrl = apiUrl + `query/${index}/${email}`;

        console.log(fetchUserUrl)

        let appCheckTokenResponse;

        try {
            appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
        } catch (err) {
            console.log(err);
        }
        // TODO: why react.strict will make axios call 2 times? even though the outer function is just called 1 time
        await axios.get(fetchUserUrl, {
            headers: {
                'X-Firebase-AppCheck': appCheckTokenResponse.token,
            }
        })
            .then(res => {
                if (res.data.domain) {
                    // setExisted(true);
                    // setCreated(false);
                    setDomain(res.data.domain);//put domain into context, localStorage is not recommended
                    sendEmail(appCheckTokenResponse.token,res.data.domain);
                } else {
                    // setCreated(true);
                    findAvailableVM(appCheckTokenResponse.token,index);
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    // can't move up, need to be executed after isExisted() is rendered
    if (user) {
        const UserImpl = user.multiFactor.user;
        provider = UserImpl.providerData[0].providerId.split(".")[0];
        email = UserImpl.email;
        firstName = UserImpl.displayName.split(" ")[0];
        lastName = UserImpl.displayName.split(" ")[1];
        const url=window.location.search;
        isExisted(url.indexOf("automation")>=0?1:0);
    }

    return (
        <div>
            {user ? (
                <Redirect to={{ pathname: "/info" }} />
            ) : (
                <div className="login-container">
                    <div className="login-content">
                        <h1 className="login-title">Sign Up</h1>
                        <div className="firebase">
                            <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                        </div>
                        {/* <div className="or-separator">
                            OR
                        </div>
                        <LoginForm/> */}
                        <div className="policy">
                            <span>By continuing you agree to the <a href='https://www.dtonomy.com/terms/' style={{ 'color': '#00EF9B' }}>terms of service</a> and <a href='https://www.dtonomy.com/privacy/' style={{ 'color': '#00EF9B' }}>privacy policy</a>.</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

class LoginForm extends Component {
    state = {
        email: '',
        companyName: ''
    };

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-item">
                    <input type="email" name="email" style={{ background: 'transparent', borderColor: '#494b49c7', color: 'white' }}
                        className="form-control" placeholder="Email"
                        value={this.state.email} onChange={this.handleInputChange} required />
                </div>
                <div className="form-item">
                    <input type="companyName" name="companyName" style={{ background: 'transparent', borderColor: '#494b49c7', color: 'white' }}
                        className="form-control" placeholder="Company Name"
                        value={this.state.companyName} onChange={this.handleInputChange} required />
                </div>
                <div className="form-item">
                    <button type="submit" className="signup-btn">Sign Up</button>
                </div>
            </form>
        );
    }
}