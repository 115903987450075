import React, { useEffect, useState } from "react";
import {app} from "./config";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [existed, setExisted] = useState(false);
  const [created, setCreated] = useState(false);
  const [domain, setDomain] = useState(null);
  const [manual, setManual] = useState(false);

  useEffect(() => {
    app.auth().onAuthStateChanged(currentUser=>{
        setUser(currentUser);
    });
    return()=>{//prevent memory leak
      setExisted(false);
      setCreated(false);
      setDomain(null);
      setManual(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user,setExisted,existed,setCreated,created,domain,setDomain,manual,setManual }}>{children}</AuthContext.Provider>
  );
};